@import "_variables";

.IBO__form--wrapper{
    background: #FBFAF9;
    border-top: 1px solid #EBE8E2;

   .inner-section{
      padding: 1.5rem;
    }

    form{
      padding: 1.5rem;
    }
  
    button{
        outline: 0px;
    }

    input, textarea{
        border: 1px solid #A9A9A9;
        padding: .5rem;
        font: 400 13px "MyriadPro-Regular", sans-serif;
    }

    textarea{
        resize: vertical;
    }

    hr{
        border-top: 1px solid #F2F2F2;
        margin: 10px 0
      }

      label{
        font-weight: bold;
        font-size: 14px;
        height: 100%;
        font-family: "MyriadPro-Regular", sans-serif;
      }

      p, span{
        font: 400 13px "MyriadPro-Regular", sans-serif;
      }



     label sup{
        color: $color-red
      }

      .form__group--title{
          font-size: 24px;
      }
      .error-content{
          padding: 0;
          min-height: auto;
          margin: 0
      }
      .text-danger{
        color: $color-red;
    }

    .form-check,.form-check-label,.radio-btn{
      cursor: pointer;
    }

    .form-check-label{
      font-weight: 300
    }
  }