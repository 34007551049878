// import global themes (optional)

// import helpers - this goes first to establish variables if needed
@import './3-helpers/helpers-dir';

// import vendor (optional for all third party libraries)
// @import './4-vendor/vendor-dir';

// import directories - these go last to override any vendor items
@import './0-base/base-dir';
@import './1-pages/pages-dir';
@import './2-modules/modules-dir';

// import print styles
@import 'print';

@import "global.scss";
// @import "~angular-calendar/css/angular-calendar.css";
// @import "~simplebar/dist/simplebar.css";
// @import "~@ng-select/ng-select/themes/default.theme.css";
@import "button.scss";
@import "form.scss";
@import "typography.scss";
@import "_variables.scss";

$bgPath: '/assets/images/';

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: $font-regular, sans-serif;
  font-size: 12px;
  text-align: inherit;
}

label {
  font-weight: bold;
}

.cms-site-wrapper {
  svg {
    font-family: $font-regular, sans-serif;
  }

  textarea {
    resize: vertical;
  }

  .wrapper.container-fluid {
    min-height: 100%;
    padding: 0;
    margin: 0;
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: inherit;
  }

  .title-search > h1 {
    font: 3rem "MyriadPro-Regular" !important;
    margin: 20px 0;
  }

  .ibo_globalsearach--input {
    font-size: inherit;
    font-weight: inherit;
  }

  .result h3 {
    margin-top: 1.5%;
    font-size: 2rem;
  }

  .ibo--item-search-result {
    margin-top: 2rem;
  }

  .ibo--item-search-result > h5 {
    margin-top: 2%;
  }

  .ibo--item-search-pageUrl > a, .ibo--item-search-pageUrl > a:hover {
    color: #0075da !important;
    font-size: inherit !important;
  }

  .ibo--item-search-result > p {
    margin-top: 1rem;
  }

  hr {
    margin: 0;
    border: 0;
  }

  body.langArabic {
    direction: rtl;
    unicode-bidi: embed;
  }

  .loader {
    border: 3px solid #c6c2c2;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .ng-dropdown-panel.ng-select-bottom {
    border: 1px solid #e9e0e0;
  }

  body .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 100px;
    background-color: #fff;
    width: 100%;
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .cdk-overlay-pane .owl-dt-popup {
    top: 146.891px;
    left: 37px;
  }

  .drop-ibo button::after {
    display: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .IBO-loader {
    background: url(/assets/images/icon-loading.gif) no-repeat 17px center #fff;
    width: 150px;
    height: 50px;
    padding-left: 10px;
    border-radius: 9px;
    box-shadow: 0 2px 6px 0 #c4c8d0;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 9;

    p {
      margin: 15px 0 0 50px;
      font-size: 13px;
    }
  }


  .ng-select.custom.ng-select-multiple .ng-select-container.ng-has-value {
    overflow-y: hidden;
    max-height: 100px;
  }

  .ng-select.custom.ng-select-multiple .ng-select-container.ng-has-value:hover {
    overflow-y: auto;
    max-height: 100px;
  }
  // Typography
  .IBO-title {
    color: #004A8D;
    font-size: 30px/43px;
    font-family: $font-regular;
  }

  .IBO-content-wrapper {
    padding: .5rem 1.5rem;
  }

  .IBO {
    .page-content {
      background: #FFFFFF;
      box-shadow: 0px 0px 4px #c0c0c0;
      border-radius: 4px;
    }
  }

  .quilInput .ql-snow .ql-tooltip {
    left: auto !important;
  }

  .breadcrumb {
    padding: 0.75rem 1.5rem;
  }



  .lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto;
  }

  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }

  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #4a74bb;
  }

  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  .IBO__badge {
    display: inline-block;
    padding: 0.35em .65em;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .45rem;
    background: #ebf5ff;
    margin-right: 10px;
    margin-bottom: 5px;
  }


  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }

  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }

  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }

  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .main__section--bg {
    background: #FBFAF9;
  }

  .rounded-top-left-none {
    border-top-left-radius: 0px !important
  }
  // table

  .ibo-table th {
    font-size: 15px;
    background: #fff;
  }

  section div.user-operations table.ibo-table {
    background: #CCC;
    overflow: hidden;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  // Model box css changes
  .modal-backdrop.show {
    opacity: 0.75;
  }

  .modal-dialog-item .modal-item-content h2.title {
    color: #004A8D;
    font-size: 24px;
    border-bottom: 1px solid #ECECEC;
  }
  // input
  .ng-select.seach-input .ng-select-container {
    border-radius: 16px;
  }

  .modal-dialog-item .modal-item-content .user .ng-placeholder {
    text-indent: 0px;
  }

  .ng-select.seach-input .ng-arrow-wrapper {
    background: url(/assets/images/search-icon.svg) no-repeat 0px center #fff;
  }




  button:disabled {
    cursor: no-drop;
  }

  button {
    &:focus {
      outline: 0; // outline: 2px solid #4a74bb; Old AX related outline so removing it
    }
  }

  .loaderDiv {
    display: flex;
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: transparent;
  }

  .Language .ng-dropdown-panel .total-padding {
    width: 324px;
    opacity: 0;
    height: 96px !important;
  }

  .ng-dropdown-panel .scrollable-content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;
  }

  .Language .ng-dropdown-panel .scroll-host {
    // overflow: hidden;
    width: 100%;
    border: 1px solid #00a9e6;
    border-top: none;
  }

  .ibo--main-section .center-container.bg-none {
    background: none;
  }

  section .inner-section table.ibo-table tr.row-search input {
    padding-right: 18% !important;
  }

  .ibo-df-btn {
    background: #fff;
    border-radius: 22px;
    border: 0;
    font-size: 14px;
    line-height: 0;
    padding: 20px 38px;
    margin-right: 30px;
  }

  .alert {
    position: fixed;
  }

  .text-danger {
    margin-top: 0px !important;
    margin-bottom: 18px !important;
  }

  .search-wrap {
    border: none;

    form {
      display: inline-flex;
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      height: 35px;
      width: 130px;
      position: relative;

      input {
        width: 100%;
      }

      img {
        cursor: pointer;
        float: right;
        padding-right: 5px;
        position: absolute;
        right: 0;
        width: 30px;
        height: 24px;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .ibo--message-dismiss {
    background: url("/assets/images/icon-dismiss-respose-default.svg") no-repeat 0 0;
    padding: 0.4rem 1rem;
    display: block;
    text-indent: -200rem;
    overflow: hidden;
    cursor: pointer;
    border: none;
  }

  table.ibo-table td.updatedDate {
    padding: 14px 10px !important;
  }

  .ibo--bookmark {
    border: 0;
    padding: 0;
    margin-right: 0;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    background: url("/assets/images/bookmark-icon.svg") center center no-repeat;
  }

  .ibo--bookmark:disabled {
    background: url("/assets/images/bookmark-icon-active.svg") center center no-repeat;
  }

  .form-placeholder-popup {
    .text-danger {
      margin-top: 5px !important;
    }

    .ibo--popup-backscreen {
      z-index: 9;
    }
  }

  .slds-spinner_container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9050;
    background-color: hsla(0, 0%, 100%, .75);
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease, visibility 0s;
    transition-delay: 0s, .3s
  }

  .slds-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9051;
    transform: translate(-50%, -50%) rotate(90deg)
  }

  .slds-spinner,
  .slds-spinner__dot-a,
  .slds-spinner__dot-b {
    transform-origin: 50% 50%;
    will-change: transform
  }

  .slds-spinner__dot-a,
  .slds-spinner__dot-b {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
  }

  .slds-spinner:after,
  .slds-spinner:before,
  .slds-spinner__dot-a:after,
  .slds-spinner__dot-a:before,
  .slds-spinner__dot-b:after,
  .slds-spinner__dot-b:before {
    content: "";
    position: absolute;
    background: #b0adab;
    border-radius: 50%;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform: translateZ(0)
  }

  .slds-spinner__dot-a {
    transform: rotate(60deg)
  }

  .slds-spinner__dot-b {
    transform: rotate(120deg)
  }

  .slds-spinner:before {
    animation-delay: -83.33333ms
  }

  .slds-spinner__dot-a:before {
    animation-delay: 83.33333ms
  }

  .slds-spinner__dot-b:before {
    animation-delay: .25s
  }

  .slds-spinner:after {
    animation-delay: .41666667s
  }

  .slds-spinner__dot-a:after {
    animation-delay: 583.33333ms
  }

  .slds-spinner__dot-b:after {
    animation-delay: .75s
  }

  .slds-spinner--brand.slds-spinner:after,
  .slds-spinner--brand.slds-spinner:before,
  .slds-spinner--brand .slds-spinner__dot-a:after,
  .slds-spinner--brand .slds-spinner__dot-a:before,
  .slds-spinner--brand .slds-spinner__dot-b:after,
  .slds-spinner--brand .slds-spinner__dot-b:before,
  .slds-spinner_brand.slds-spinner:after,
  .slds-spinner_brand.slds-spinner:before,
  .slds-spinner_brand .slds-spinner__dot-a:after,
  .slds-spinner_brand .slds-spinner__dot-a:before,
  .slds-spinner_brand .slds-spinner__dot-b:after,
  .slds-spinner_brand .slds-spinner__dot-b:before {
    background-color: #0075DA
  }

  .slds-spinner--large,
  .slds-spinner_large {
    width: 3.75rem
  }

  .slds-spinner--large.slds-spinner_inline,
  .slds-spinner_large.slds-spinner_inline {
    height: 2.75rem
  }

  .slds-spinner--large.slds-spinner:after,
  .slds-spinner--large.slds-spinner:before,
  .slds-spinner--large .slds-spinner__dot-a:after,
  .slds-spinner--large .slds-spinner__dot-a:before,
  .slds-spinner--large .slds-spinner__dot-b:after,
  .slds-spinner--large .slds-spinner__dot-b:before,
  .slds-spinner_large.slds-spinner:after,
  .slds-spinner_large.slds-spinner:before,
  .slds-spinner_large .slds-spinner__dot-a:after,
  .slds-spinner_large .slds-spinner__dot-a:before,
  .slds-spinner_large .slds-spinner__dot-b:after,
  .slds-spinner_large .slds-spinner__dot-b:before {
    width: .900rem;
    height: .900rem;
  }

  .slds-spinner--large.slds-spinner:before,
  .slds-spinner--large .slds-spinner__dot-a:before,
  .slds-spinner--large .slds-spinner__dot-b:before,
  .slds-spinner_large.slds-spinner:before,
  .slds-spinner_large .slds-spinner__dot-a:before,
  .slds-spinner_large .slds-spinner__dot-b:before {
    animation-name: dotsBounceBefore-large;
    top: -.4125rem;
    left: -.4125rem
  }

  .slds-spinner--large.slds-spinner:after,
  .slds-spinner--large .slds-spinner__dot-a:after,
  .slds-spinner--large .slds-spinner__dot-b:after,
  .slds-spinner_large.slds-spinner:after,
  .slds-spinner_large .slds-spinner__dot-a:after,
  .slds-spinner_large .slds-spinner__dot-b:after {
    animation-name: dotsBounceAfter-large;
    top: -.4125rem;
    right: -.4125rem
  }

  @keyframes dotsBounceBefore-large {
    0% {
      transform: translateZ(0)
    }

    60% {
      transform: translateZ(0);
      animation-timing-function: cubic-bezier(.55, .085, .68, .53)
    }

    80% {
      transform: translate3d(-.75rem, 0, 0);
      animation-timing-function: cubic-bezier(0, 1.11, .7, 1.43)
    }

    to {
      transform: translateX(0)
    }
  }

  @keyframes dotsBounceAfter-large {
    0% {
      transform: translateZ(0)
    }

    60% {
      transform: translateZ(0);
      animation-timing-function: cubic-bezier(.55, .085, .68, .53)
    }

    80% {
      transform: translate3d(.75rem, 0, 0);
      animation-timing-function: cubic-bezier(0, 1.11, .7, 1.43)
    }

    to {
      transform: translateX(0)
    }
  }

  .view-more {
    .pd-10 div:nth-child(3) p:nth-child(2) {
      min-height: 2rem;
      max-height: 20rem;
      overflow: auto;
    }
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .row-head {
    text-align: left;
  }

  .row-search {
    text-align: left;
  }

  .float-right {
    float: right !important;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
  }

  .d-flex {
    display: flex !important;
  }

  .ibo--main-section {
    .content-right {
      section {
        .pageNator {
          margin-bottom: 1.3rem;

          .ibo-paging {
            a, a:hover {
              display: inline-block;
              padding: 1.5rem 1.5rem !important;
              border: 1px solid #e8e9ec !important;
              line-height: 0;
              margin-right: 0.8rem !important;
              border-radius: 0.3rem !important;
              color: #4d4f5c !important;
              text-decoration: none;

              &.ibo-paging-prev {
                border: 0px solid #e8e9ec !important;
              }

              &.ibo-paging-next {
                border: 0px solid #e8e9ec !important;
              }
            }

            .active {
              a, a:hover {
                border-width: 0 !important;
              }
            }
          }
        }

        table.ibo-table {
          min-width: 1300px;

          button {
            background-position: center center !important;
          }
        }
      }
    }
  }

  .ibo--grid-row-no-margin, .ibo--grid-row {
    .page-title {
      width: auto;
    }
  }



  .pagination-csv {
    justify-content: flex-end !important;
    // height: 80px;
    overflow: visible;
    flex-wrap: wrap;
    font-size: 15px;
  }

  .ibo--popup-container .ibo--popup-body {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &.site-wrapper {
    .user-operations-view {
      overflow-x: auto;
    }

    .center-container {
      margin: 0 auto;
      width: 1300px;
      padding: 0 26px 0 24px;
    }

    .main-section {
      flex: 1;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      background-color: #EAEAEA;

      .content-right {
        section, div {
          .pagination-csv {
            justify-content: flex-end !important;
            // height: 80px;
            overflow: visible;
            flex-wrap: wrap;
            font-size: 15px;
          }

          .pageNator {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 15px;
            color: #4D4F5C;
            margin-bottom: 25px;

            select {
              appearance: none;
              background: url('/assets/images/icon-drop-arrow.svg') no-repeat 75% center;
              vertical-align: middle;
              margin: 0 10px;
              border: 1px solid #E9E9F0;
              border-radius: 4px;
              color: #4D4F5C;
              padding: 10px;
              padding-right: 30px;
            }

            .ibo-paging {
              margin-bottom: 0;
              margin-left: 30px;
              display: flex;

              a,
              a:hover {
                display: inline-block;
                padding: 16px 15px;
                border: 1px solid #E8E9EC;
                line-height: 0;
                margin-right: 8px;
                border-radius: 3px;
                color: #4D4F5C;
                text-decoration: none;
              }

              li.active a {
                border: 1px solid #4A74BB;
              }

              li.disabled a {
                opacity: 0.5;
                cursor: not-allowed;
              }

              a.ibo-paging-prev,
              a.ibo-paging-next {
                background: url('/assets/images/btn-paging-prev.svg') no-repeat;
                text-indent: -9999px;
                border: 0;
                padding: 18px;
              }

              a.ibo-paging-next {
                background: url('/assets/images/btn-paging-next.svg') no-repeat;
              }
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              appearance: none;
              margin: 0;
            }

            input[type=number] {
              -moz-appearance: textfield;
              appearance: textfield;
              border: 1px solid #E9E9F0;
              border-radius: 4px;
              padding: 10px;
              width: 70px;
              margin-left: 10px;
            }
          }

          table.ibo-table {
            min-width: 1220px;
            font-size: 13px;
          }
        }
      }
    }

    section .inner-section .table-overview {
      overflow: auto;
    }

    .main-section .content-right section .sub-title {
      @media screen and (max-width:479px) {
        flex-wrap: wrap;

        h1 {
          min-width: 100%;
        }

        button {
          margin-left: auto;
        }
      }
    }

    @media screen and (max-width: 600px) {
      nav.breadcrumb {
        ol {
          li {
            display: inline-block !important;

            a, span {
              display: inline-block;
            }
          }
        }
      }
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #000000bf;
      padding: 2rem;
      justify-content: center;
      z-index: 11111;
      align-items: center;
      display: flex !important;

      .modal-dialog {
        display: inline-flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 0.4rem;
        padding: 2rem 0;
        min-width: 65rem;
        max-width: 131.6rem;

        .modal-content {
          position: relative;

          .ibo-btn-close-corner {
            text-indent: -200rem;
            overflow: hidden;
            display: inline-block;
            padding: 0.3rem 1.2rem 0.3rem 1.2rem !important;
            vertical-align: middle;
            position: absolute;
            line-height: 2.4;
            background-position: top center;
            width: 1.5rem;
            height: 1.5rem;
            border: hidden;
            right: 21px !important;
            top: 5px !important;

            &:focus {
              outline: 0;
            }
          }

          .modal-header {
            border-bottom: 1px solid #ececec;
            padding: 0.5rem 2.1rem 1.4rem 2.5rem !important;

            .modal-title {
              border-bottom: 0;
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }

          .pd-10 {
            padding: 3rem 2.5rem;
          }
        }
      }
    }

    .aosLive {
      .picker-container {
        position: relative;

        .calendar {
          position: absolute;
          right: 0;
        }
      }
    }

    table.ibo-table {
      tr.row-search {
        .search-wrap {
          &.mgmt-cnt {
            form {
              position: relative;

              input {
                background: none;
                border: 1px solid #cdcdcd;
                padding-left: 5px;

                &:focus {
                  outline: 0;
                }

                & + img {
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }

    .item-search-division {
      label {
        margin-bottom: 10px;
        display: inline-block;
      }
    }
  }
}


