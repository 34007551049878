/* ========================
CONSTANTS
=========================*/

// COLOR PALETTE

// Primary Colors

// Accenet Colors

// Base Colors
$white: #ffffff;
$black: #000000;

// Gradients

// FONTS

// STANDARD SIZES
$radius: 0.125rem;

// Z-LAYERS
$layer-1: 1;
$layer-2: 2;
$layer-3: 3;
$layer-4: 4;
$layer-5: 5;
