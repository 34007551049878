$footerBg:#13B6EA;
$white:#fff;
$breadcrumb-divider: none;

.btn-icon {
	border:0;
  background:none;
  cursor: pointer;

	.size-icon {
		font-size: 1.5em;
		line-height: 0;
	}
}

.ibo-row{
	display: flex;
}


ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a, a:hover
{
	text-decoration: none;
}


.ibo-tooltip {

    position: absolute;
    font-size: 13px;
    max-height: 150px;
    max-width: 150px;
    background: #4269ae;
    color: #fff;
    word-break: break-word;
    white-space: normal;
    border-radius: 6px;
    padding: 10px;
    top: 12px;
    z-index: 1;

}



.ibo-tooltip:after {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(66, 105, 174, 0);
  border-bottom-color: #4269ae;
  border-width: 12px;
  margin-left: -12px;
}

input.ng-invalid.ng-touched  {
  border: 1px solid red !important;
}

textarea.ng-invalid.ng-touched  {
  border: 1px solid red !important;
}