// styles.scss

@import "_variables";

%btn-shared {
    display: inline-block;
    font: 600 15px/20px "MyriadPro-Regular", sans-serif;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    background-color: $color-primary; 
    border: 1px solid transparent;   
    color: #FFFFFF;
    min-width: 98px;
    padding:0 16px;
    border-radius: $border-radius;
    line-height: 32px;
    margin:0.2rem 0
  }

  %btn-icon {
    background-repeat: no-repeat;
    display: inline-block;
    width: 22px;
    height: 15px;
    background-position: center 2px;
    background-size: contain;
  }



 // base styles

 button:focus{
    outline: 0
 }

.IBO__btn{
    background-color: $color-primary;
    margin-right: 10px;
    @extend %btn-shared;

    &:hover {
        background-color: $color-brand; 
    }

    &:disabled, &--disabled, &--disabled:hover{
        background-color: $color-disable; 
        border-color: $color-disable;
        color: $color-white;
        cursor: not-allowed;
    }
}

.IBO_df-btn{
    display: flex;
    align-items: center;
}

.IBO__ico{
    @extend %btn-icon;
    &-add{
        background-image: url('/assets/images/icon-assessment-period-add.svg');       
    }
    &-track{
        display: inline-block;
    width: 15px;
    height: 20px;
    margin-right: 10px;
    background: url(/assets/images/track-changes.svg) no-repeat 0px 0px;   
    }
}

.IBO__btn--primary{
    background-color: $color-primary;
    @extend %btn-shared;

    &:hover {
        background-color: $color-brand; 
    }

    &:disabled, &--disabled, &--disabled:hover{
        background-color: $color-disable; 
        border-color: $color-disable;
        color: $color-white;
        cursor: not-allowed;
    }

    &:disabled .IBO__ico-track {
        background-image: url('/assets/images/track-changes-disabled.svg');
    }

}


.IBO__btn--secondary {
  background-color: $color-secondary;
  border-color: $color-primary;
  color: $color-primary;
  margin-right: 10px;
  @extend %btn-shared;

  &:hover {
    background-color: $color_secondary-hover;
    border-color: $color-hover;
    color: $color-hover;
  }

  &:disabled, &--disabled, &--disabled:hover {
    background-color: $color-white;
    border-color: $color-disable;
    color: $color-disable;
    cursor: not-allowed;
  }

  .IBO__ico-add {
    @extend %btn-icon;

    &-add {
      background-image: url('/assets/images/icon-assessment-period-add.svg');
    }
  }
}

