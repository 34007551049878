
/* 
**  colors
*/
$color-brand: #004A8D;
$color-primary: #4A74BB; 
$color-hover: #004B8C;
$color_secondary-hover : #E6E6E6 ;
$color-secondary: #FFFFFF;
$color-disable: #B9BBBD;

/* 
** Text colors
*/
$text-primary: #333333;
$text-secondary: #666666;
$text-heading-color: $color-brand;
$text-anchor-color:#0075DA;

/* 
** Common colors
*/
$color-green: #1D8730;
$color-red: #CB0C0C;
$color-Gossip :#E2F9BE;
$color-Barley-White : #FFF6CB; 
$color-white : #ffffff;

/* font */
$font-weight-default: default;
$font-weight-bold: bold;

// btn border-radius
$border-radius: 5px;
$border-color: "#333";
$font-regular: "MyriadPro-Regular";
$font-semiBold: "MyriadPro-Semibold";
$font-bold: "MyriadPro-Bold";
